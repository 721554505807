/*############################################################################*/

/*# Global Stuff                                                             #*/

/*############################################################################*/

@import url("https://p.typekit.net/p.css?s=1&k=lww4gse&ht=tk&f=10875&a=22051901&app=typekit&e=css");
@font-face {
  font-family: "din-condensed";
  src: url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/cfbead/0000000000000000000146b3/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "din-condensed";
  src: url(./assets/fonts/dincond.otf);
  font-display: auto;
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'flounder-blackitalicBlkIt';
  src: url('./assets/fonts/flounder-black/font-webfont.woff2') format('woff2'),
       url('./assets/fonts/flounder-black/font-webfont.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "flounder";
  font-display: auto;
  font-style: italic;
  font-weight: normal;
  src: url(./assets/fonts/Flounder-MediumItalic/font.woff);
}

html {
  visibility: hidden;
}

body, html {
  width: 100% !important;
  height: 100% !important;
  font-family: "din-condensed", sans-serif;
  font-weight: 200;
}

html {
  background-color: #000;
  /* background-image: url('./assets/img/shutterstock_1358731034.jpg'); */
  background-attachment: fixed;
  background-position: center top;
  background-size: cover;
  background-repeat: no-repeat;
}

/*############################################################################*/

/*# Header layout                                                            #*/

/*############################################################################*/

.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
  z-index: 400;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
  background: -webkit-linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
  background: linear-gradient(180deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.headerLogoDKP {
  padding-top: 30px;
  padding-left: 30px;
  height: 120px;
  transition: all .5s ease;
}

.headerLogoDKP.smallLogo {
  padding-top: 30px;
  padding-left: 30px;
  height: 100px;
  transition: all .5s ease;
  /* opacity: 0; */
}

.headerLogoDKP img {
  height: 100%;
  width: auto;
  transition: all .5s ease;
}

.header .headerToggle {
  color: #fff;
  display: flex;
  height: 100%;
  align-items: center;
  padding-right: 30px;
  box-sizing: border-box;
}

/*############################################################################*/

/*# Carousel Layout                                                          #*/

/*############################################################################*/

.carouselContainer {
  width: 100%;
  height: 90vh;
  position: relative;
}

.movieSlide {
  width: 100%;
  height: 90vh;
  background-size: cover;
  background-position-x: 70%;
  background-repeat: no-repeat;
  position: relative;
  color: #fff;
}

.movieSlideCol {
  width: 50%;
  height: 75%;
  display: flex;
  z-index: 2;
  padding: 40px;
  box-sizing: border-box;
  position: relative;
}

ul.carousel-dots {
  position: relative;
  z-index: 100;
  margin-top: -170px;
}

.carousel-dots li button:before {
  color: #fff !important;
  opacity: 0.3 !important;
}

.carousel-dots li.carousel-dots-active button:before {
  color: #fff !important;
  opacity: 1 !important;
}

.movieSlideGradient {
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 70%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 70%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 2%, rgba(0, 0, 0, 0) 70%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.movieSlideContent {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
}

.movieSlidePContent {
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.moviSlidePSpacer {
  display: block;
  width: 100%;
  border: dashed 1px magenta;
}
.movieInfo {
  align-self: flex-end;
  box-sizing: border-box;
  padding-bottom: 0px;
}

.movieSlideTitle {
  display: block;
  width: 90%;
}

.movieSlideverticalTitle {
  display: block;
  width: 90%;
}

.movieSlideverticalTitle.le-dernier-sac img{
  max-height: 32vh;
}

.movieSlideverticalTitle.ketchup-vision img{
  max-height: 30vh;
}
.movieSlideverticalTitle img {
  max-height: 32vh;
  margin-bottom: 5px;
}
.movieSlideTitle img {
  width: 100%;
  margin-bottom: 20px;
}

.movieSumary {
  font-weight: 400;
  margin-top: 0px;
  font-size: 1.3em;
  margin-bottom: 10px;
  color: #fff;
}

.movieMeta {
  font-size: 1em;
  font-family: "din-condensed", sans-serif;
  color: #BABABA;
}

.movieSlidePlay {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100px;
  height: 100px;
  z-index: 3;
}

.smallPlay {
  position: relative;
  margin: 0px 0px 0px 0px
}

/* ELASTIC CAROUSEL CUSTOMS */

.carouselContainer .rec-pagination {
  position: absolute;
  bottom: 80px;
  z-index: 20;
}

.carouselContainer .rec-slider-container {
  margin: 0 0 !important;
}

.carouselContainer .rec-dot {
  background-color: rgba(255, 255, 255, .5) !important;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, .6) !important;
}

.carouselContainer .rec-dot_active {
  background-color: rgba(255, 255, 255, 1) !important;
  box-shadow: 0 0 1px 3px rgba(0, 0, 0, .6) !important;
}

.homeContent {
  margin-top: -100px;
  position: relative;
  z-index: 40;
}

.categoryContainer {
  width: 100%;
  padding: 20px 0px 40px 50px;
  box-sizing: border-box;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 3;
  position: relative;
}

.categoryTitle {
  font-size: 2.5rem;
  color: #fff;
  font-weight: bold;
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  text-transform: uppercase;
  font-style: italic;
  z-index: 50;
  position: relative;
}

.poster {
  width:100%;
  cursor: pointer;
  padding-right: 5px;
}
.poster:first-child {
  margin-left: 0px;
}

.hide-xl {
  display: none;
}

.footerHero {
  font-size: 2.5em;
  ;
}

.singleSocial {
  position: absolute;
  right: -310px;
  bottom: 180px;
}

.metaSocial {
  max-width: 200px !important;
}

.metaSocial .singleSocialTag {
  text-align: left !important;
}

.singleSocialTag {
  display: block;
  text-align: center;
  text-transform: uppercase;
  font-size: 2em;
  margin-top: 30px;
  margin-bottom: 20px;
}

.movieClose {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: 100;
  width: 100px;
}

.ageGateContainer {
  width: 100%;
  margin: 0px auto;
  padding-top: 10%;
  color: #fff;
}

.ageGate {
  width: 100%;
  margin: 0px auto;
}

.ageGateLogo img {
  max-height: 120px;
}
.movieSlideTitle.le-dernier-sac img{
  max-height: 25vh;
  width: auto;
}

.formInfo {
  display: block;
  text-align: center;
  font-size: 1.5em;
  text-transform: uppercase;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: "flounder";
  font-weight: normal;
  font-style: italic;
}

.formTitle {
  display: block;
  text-align: center;
  font-size: 1.7em;
  font-family: "din-condensed", sans-serif;
  margin-bottom: 20px;
  font-weight: normal;
}

.close-player-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
  width: 45px;
}

.lang_toggle {
  transform: scale(.75);
  top: 10px;
  right: 10px;
  position: absolute;
  transition: .4s ease;
  opacity: 1;
}

.lang_toggle_off {
  opacity: 0;
}

.inputs {
  display: flex;
  justify-content: space-between;
  max-width: 440px;
  margin: 0px auto;
}

.inputs input {
  border: solid 2px #EC1E24;
  color: white;
  padding: 5px 10px;
  border-radius: 70px;
  text-align: center;
  font-size: 2em;
  width: 140px;
}

.singleContent {
  z-index: 12;
  position: relative;
  display: block;
  margin-top: -30%;
}

.sendButton {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  height: 70px;
}

.sendButton .hero_cta {
  font-size: 2em;
}

.ageGateError {
  font-size: 1.4em;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  text-align: center;
  margin-top: 20px;
  font-family: "din-condensed", sans-serif;
}

.videoContainer {
  width: 100% !important;
  height: 100% !important;
  display: block;
  color: #fff;
  background-color: black;
}

.player-wrapper {
  height: 100% !important;
  margin: 0px auto;
}

.video-player {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

/*############################################################################*/

/*# PRODUCT SLIDE                                                            #*/

/*############################################################################*/

.movieProdCol {
  width: 49.3%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 400;
  padding-bottom: 160px;

}

.movieProdColLeft {
  justify-content: flex-end;
  align-items: center;
}

.movieProdColRight {
  justify-content: flex-start;
}

.snack {
  font-size: 4.5em;
  font-family: "flounder-blackitalicBlkIt";
  text-transform: uppercase;
  text-align: center;
  max-width: 400px;
  white-space: nowrap;
  font-style: italic;
  line-height: 1;
  font-weight: bolder;
  z-index: 2;

}

.snack.fr {
  font-size: 3em;
}

.snack .hero_cta {
  margin-top: 10px;
  font-size: .6em;
}

.productImage {
  height: 100%;
  max-height: 500px;
  /* was 420 nick fix */
  z-index: 2;
  /*nick  added this*/
}

/*############################################################################*/

/*# .categoryContainer                                                       #*/

/*############################################################################*/

.posterContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding-left: 30px;
}

.posterRow {
  width: 100%;
}

.posterRowTitle {
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 0px;
  color: #fff;
  font-family: "flounder";
  font-weight: bolder;
  font-style: italic;
  font-size: 2em;
  text-transform: uppercase;
}

/*############################################################################*/

/*# Footer   Layout                                                          #*/

/*############################################################################*/

.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 50px;
}

.footerRow {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 50px;
  padding: 20px;
  margin-bottom: 20px;
}

.hungry {
  font-size: 4em;
  font-family: "flounder";
  font-weight: bolder;
  text-transform: uppercase;
  font-style: italic;
  color: #fff;
}

.footerCol {
  color: #fff;
  display: flex;
  justify-content: center;
  text-align: center;
}

.taglineCol {
  display: flex;
}

.taglineColLeft {
  padding-top: 15px;
  padding-right: 15px;
  justify-content: flex-end;
}

ul.footerLinks {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
  max-width: 700px;
}

ul.footerLinks li {
  text-align: center;
  display: block;
}

ul.footerLinks li a {
  color: #fff;
  text-decoration: underline;
  padding-bottom: 5px;
  font-size: 1.2em;
  font-weight: normal;
  display: block;
}

.doritosLogo {
  height: 75px;
  cursor: pointer;
}

.footer_share_links_text {
  display: block;
  width: 100%;
  text-align: center;
  font-size: 1.2em;
  text-transform: uppercase;
  padding-top: 0px;
}

.socialCol {
  flex-wrap: wrap;
  flex-grow: 1;
  justify-content: center;
  padding-top: 10px;
}

.hero_cta.footerHero {
  align-self: center;
}

.ctaCol {
  flex-grow: 1;
}

.taglineCol {
  flex-grow: 2;
  align-content: center;
}

.logoCol {
  flex-grow: 1;
  justify-content: center;
  margin-bottom: 30px;
}

.linksCol {
  flex-grow: 3;
  justify-content: center;
  margin-bottom: 40px;
}

.copyRightCol {
  flex-grow: 1;
  flex-wrap: wrap;
  justify-content: center;
}

.copyright {
  text-align: center;
}

/*############################################################################*/

/*# UI Stuff                                                                 #*/

/*############################################################################*/

.hero_cta {
  border-radius: 60px;
  padding: 7px 45px 7px 43px;
  background-color: rgba(255, 0, 6, 1);
  color: #fff;
  font-family: "flounder-blackitalicBlkIt";
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  white-space: nowrap;
  z-index: 300;
  transition: all .3s ease;
}

.hero_cta:hover, .hero_cta:active, .hero_cta:visited {
  background-color: rgba(255, 0, 6, .7);
}

.playButton {
  border-radius: 50%;
  display: flex;
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, .5);
  position: relative;
  cursor: pointer;
  transition: all .3s ease;
}

.playButton:hover, .playButton:active, .playButton:visited {
  background-color: rgba(0, 0, 0, .8);
}

.playButton img {
  width: 50px;
  padding-left: 10px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.shareLinks {
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 165px;
}

.shareLinks img {
  width: 44px;
}

.react-multiple-carousel__arrow--right {
  right: 0px !important;
  background: -moz-linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  background: -webkit-linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  background: linear-gradient(270deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1)!important;
}

.react-multiple-carousel__arrow--left {
  left: 0 !important;
  background: -moz-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  background: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%)!important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1)!important;
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 0px !important;
  z-index: 2000;
  border: 0;
  height: 100%;
  min-width: 60px !important;
  min-height: 20px;
  opacity: 1;
  cursor: pointer;
  border: none !important;
  /* display: none !important; */
}

.react-multiple-carousel__arrow::before {
  font-size: 33px !important;
  text-shadow: 0px 0px 5px #000;
  font-weight: bold !important;
}


.rec-slider-container {
  margin: 0px 0px 0px 10px !important;
  position: relative;
}


/*############################################################################*/

/*# Single Hero                                                              #*/

/*############################################################################*/

.singleHero {
  background-color: #000;
  display: block;
  height: auto;
}

.singleHeroImageContainer {
  background-color: #000;
  position: relative;
  height: 100%;
  width: 100%;
  padding-bottom: 220px;
}

.singleHeroImageContainer::after {
  clear: both;
  content: "";
  display: block;
}

.singleHeroImage {
  width: 100%;
  position: relative;
  z-index: 1;
}

.singleHeroGradient {
  position: absolute;
  z-index: 10;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 120%;
  background: rgb(0, 0, 0);
  background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 43%, rgba(0, 0, 0, 0) 85%);
  background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 43%, rgba(0, 0, 0, 0) 85%);
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 43%, rgba(0, 0, 0, 0) 85%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.singleHeroContainer {
  width: 100%;
  height: 100%;
  background-color: #000;
  margin-top: -100%;
  position: relative;
  z-index: 0;
  padding-bottom: 100px;
}

.singleHeroContent {
  position: absolute;
  z-index: 10;
  height: 100%;
  width: 100%;
  display: flex;
  top: 0px;
  justify-content: space-between;
}

.singleHeroCol {
  width: 45%;
  display: flex;
  height: 60%;
}

.singleMovieInfo {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  align-self: flex-end;
  text-shadow: 0px 0px 10px rgba(0, 0, 0, .1);
  padding-left: 30px;
  padding-bottom: 60px;
}

.singleHeroSocial {
  color: #fff;
  align-self: flex-end;
}

.singleHeroSocial .shareLinks {
  transform: scale(0.7);
}

.singleSocialTag {
  margin-top: 0px;
  font-size: .8em;
}

.singleHeroColSocial {
  justify-content: flex-end;
}

.singleHeroPlay {
  position: absolute;
  top: 23%;
  left: 50%;
  bottom: 0;
  width: 100px;
  height: 100px;
  z-index: 50;
  margin-left: -20px;
}

.carousel-dots li button:before {
  font-size: 10px !important;
}

.slick-prev {
  left: 30px !important;
  z-index: 100 !important;
  transition: all .3s ease;
  height: 100% !important;
}

.slick-next {
  right: 30px !important;
  z-index: 100 !important;
  transition: all .3s ease;
}
.slick-disabled {
  opacity: 0;
}

.slick-arrow {
  z-index: 300;
  margin-left: -10px;
  
}
.slick-prev:before, .slick-next:before {
  font-size: 40px !important;
}

.slick-prev, .slick-next {
  width: 40px !important;
  height: 40px !important;
}

.slick-dots button:before {
  opacity: .7 !important;
  color: #fff !important;
  font-size: 9px !important;
}


.slick-dots li.slick-active button:before {
  opacity: 1 !important;
  color: #fff !important;
}

.slick-dots {
  bottom: 125px !important;
}