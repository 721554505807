/* #### FOOTER BREAKPOINT #### */

@media screen and (max-width: 1250px) {
    .hungry {
        font-size: 3.6em;
    }
}

@media screen and (max-width: 940px) {
    .movieSlideverticalTitle img {
        max-height: 200px;
        margin-bottom: 5px;
    }

    .movieSlideverticalTitle.ketchup-vision img{
        max-height: 180px;
      }

    .movieSlideverticalTitle.le-dernier-sac img{
        max-height: 180px;
      }
    .footerRow {
        flex-direction: column;
    }
    .taglineCol {
        /* justify-content: center; */
    }
    .footerHero {
        font-size: 3.5em;
    }
    .footerHero {
        margin-top: 20px;
        transform: scale(.8);
    }
    .socialCol {
        justify-content: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
    .logoCol {
        justify-content: center;
        margin-bottom: 60px;
    }
    .linksCol {
        flex-grow: 1;
        justify-self: center;
    }
    .copyright {
        max-width: 600px;
    }
    .copyRightCol {
        margin-top: 40px;
        margin-bottom: 40px;
        justify-content: center;
    }
}

@media screen and (max-width: 800px) {
    .movieSlideverticalTitle img {
        max-height: 200px;
    }
    .playButton {
        width: 70px;
        height: 70px;
    }
    .movieSlideverticalTitle img {
        max-height: 200px;
        margin-bottom: 5px;
    }
    .playButton img {
        width: 40px;
    }
    .movieSumary {
        font-size: 1.2em;
    }
    .singleHeroImageContainer {
        padding-bottom: 150px;
    }
    .singleHeroGradient {
        height: 100%;
    }
}

@media screen and (max-width: 670px) {
    .movieSlideverticalTitle.le-dernier-sac img{
        max-height: 120px;
    }
    .movieSlideverticalTitle.ketchup-vision img{
        max-height: 17vh;
      }
    .movieSlideverticalTitle img {
        max-height: 180px;
    }
    .movieSumary {
        font-size: 1em;
    }
    .movieSlideverticalTitle img {
        max-height: 160px;
        margin-bottom: 5px;
    }
    .hungry {
        font-size: 3.6em;
    }
    .footerHero {
        font-size: 3.2em;
    }
    ul.footerLinks li a {
        font-size: 1em;
    }
    .copyright {
        font-size: .8em;
    }
}

@media screen and (max-width: 2560px) {}



@media screen and (max-height: 490px) and (orientation:landscape) {
    .slick-dots {
        bottom: 40px !important;
        z-index: 400;
    }
    .movieProdColRight {
        align-items: flex-start;
    }
    .movieProdColLeft {
        padding-top: 40px;
    }
    .snack.fr {
        font-size: 2.3em;
    }
}

@media screen and (max-width: 490px) {
    .slick-dots {
        bottom: -28px !important;
    }
    .snack.fr {
        font-size: 2.3em;
    }

    .snack.fr .hero_cta {
        padding-bottom: 10px;
    }
    .movieSlideTitle.le-dernier-sac img {
        max-height: 130px;
    }
    .movieSlideverticalTitle.le-dernier-sac img {
        max-height: 150px;
    }

    .movieSlideverticalTitle.ketchup-vision img{
        max-height: 100px;
    }
    .carousel-dots li button:before {
        font-size: 10px !important;
    }
    .movieSlideverticalTitle img {
        max-height: 140px;
        margin-bottom: 5px;
    }
    .hide-s {
        display: none;
    }
    .hide-xl {
        display: block;
    }
    .taglineColLeft {
        padding-right: 0px;
    }
    .ageGateContainer {
        padding-top: 30px;
    }
    .ageGateLogo {
        text-align: center;
    }
    .ageGateLogo img {
        max-width: 200px;
    }
    .ageForm {
        max-width: 300px;
        margin: auto;
    }
    .inputs {
        width: 280px;
        margin: auto;
    }
    .inputs input {
        font-size: 1.6em;
        padding: 5px 10px;
        width: 90px;
    }
    .sendButton .hero_cta {
        font-size: 1.95em;
    }
    .headerLogoDKP {
        padding-top: 20px;
        padding-left: 20px;
        height: 100px;
        transition: all .5s ease;
    }
    .headerLogoDKP.smallLogo {
        padding-top: 20px;
        padding-left: 17px;
        height: 60px;
        /* opacity: 0; */
    }
    .singleHeroPlay {
        width: 100%;
        position: inherit;
        padding-left: 20px;
        height: auto;
        margin-bottom: 10px;
    }
    .movieSumary {
        font-size: 1.2em;
    }
    .movieMeta {
        font-size: 1em;
    }
    .movieClose {
        top: 20px;
        right: 20px;
        width: 80px;
        z-index: 2000;
    }
    .singleContent {
        margin-top: -35%;
        position: relative;
        z-index: 2000;
    }
    .singleHeroImageContainer {
        padding-bottom: 340px;
    }
    .singleHeroGradient {
        position: absolute;
        z-index: 10;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 70%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 70%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 70%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    }
    .singleHeroContent {
        height: 80%;
        flex-direction: column;
        justify-content: flex-end;
    }
    .singleHeroSocial {
        margin-left: -10px;
        margin-top: 13px;
        margin-bottom: 10px;
    }
    .singleHeroCol {
        width: 75%;
        height: auto;
        justify-content: flex-start;
    }
    .singleMovieInfo {
        padding-left: 10px;
        padding-bottom: 10px;
    }
    .posterContainer {
        padding-left: 10px;
    }
    ul.footerLinks {
        flex-direction: column;
    }
    ul.footerLinks li a {
        font-size: 1.2em;
        margin-bottom: 10px;
    }
    .copyright {
        font-size: .7em;
    }
    .hungry {
        font-size: 2.8em;
    }
    .footerHero {
        font-size: 2.5em;
    }
    .shareLinks {
        padding-top: 0px;
        min-width: 140px;
    }
    .shareLinks img {
        width: 40px;
    }
    .footer_share_links_text {
        font-size: 1em;
    }
    .socialCol {
        margin-top: 50px;
        margin-bottom: 0px;
    }
    .doritosLogo {
        height: 60px;
        cursor: pointer;
    }
    .carouselContainer {
        width: 100%;
        height: 90vh;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 85%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 85%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 60%, rgba(0, 0, 0, 0) 85%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    }
    .movieSlidePContent {
        justify-content: flex-start;
        flex-direction: column-reverse;
        margin-top: 0px;
    }
    .snack {
        font-size: 2.1em;
    }
    .productImage {
        max-height: 2000px;
        padding-left: 0px;
    }
    .movieProdCol {
        width: 100%;
        z-index: 500;
        padding-bottom: 0px;
    }
    .movieProdColLeft {
        justify-content: center;
        align-items: flex-end;
        height: 30%;
    }
    .movieProdColRight {
        justify-content: center;
        align-items: flex-end;
        margin: 0px 0px 10px 0px;
        height: 48%;
    }
    .snack .hero_cta {
        margin-top: 10px;
        padding-bottom: 10px;
        padding-top: 12px;
    }
    ul.carousel-dots {
        margin-top: 20px;
    }
    .homeContent {
        margin-top: -85px;
        position: relative;
        z-index: 40;
    }
    .movieSlideCol {
        padding: 10px;
        width: 100%;
        height: auto;
    }
    .movieSlide {
        padding-top: 20px;
        height: 70vh;
    }
    .movieSlideContent {
        flex-direction: column-reverse;
    }
    .movieSlidePlayCol {
        align-items: flex-end;
    }
    .movieSlidePlay {
        margin-bottom: -40px;
    }
    .movieInfo {
        align-self: flex-end;
    }
}

@media screen and (max-width: 320px) {

    .homeContent {
        margin-top: -57px;
    }

    .productImage {
        max-height: 150px;
    }
    .snack {
        font-size: 1.7em;
    }
    ul.carousel-dots {
        margin-top: 5px;
    }
    .singleHeroGradient {
        position: absolute;
        z-index: 10;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 80%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 80%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 65%, rgba(0, 0, 0, 0) 80%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
    }
}

/* #LANDSCAPE */

@media screen and (max-width: 950px) and (orientation:landscape) {
    .carouselContainer {
        height: 100vh;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 40%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 40%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 40%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=.7);
    }
    .movieSlideCol {
        height: 85%;
        padding-bottom: 20px;
        padding-right: 10px;
    }
    .homeContent {
        margin-top: -67px;
    }
    .movieSumary {
        font-size: 1em;
    }
    .movieMeta {
        font-size: 1em;
    }
    .headerLogoDKP {
        padding-top: 10px;
        padding-left: 10px;
        height: 90px;
    }
    .headerLogoDKP.smallLogo {
        height: 60px;
        /* opacity: 0; */
    }
    ul.carousel-dots {
        margin-top: -50px;
    }
    .playButton {
        margin: auto;
    }
    .movieSlideTitle img {
        margin-bottom: 5px;
    }
    .movieSlide {
        height: 100vh;
    }
    .movieSlideGradient {
        height: 100vh;
        background: rgb(0, 0, 0);
        background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);
        background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);
        background: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, rgba(0, 0, 0, 0) 60%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=.7);
    }
    .snack {
        font-size: 2.5em;
    }
    .snack .hero_cta {
        margin-top: 5px;
        padding-bottom: 10px;
    }
    .movieProdCol {
        padding-bottom: 30px;
    }
    .movieProdColRight {
        padding-left: 30px;
        padding-top: 50px;
        align-items: flex-start;
    }
    .movieProdColLeft {
        align-items: center;
    }
    .productImage {
        max-height: 190px;
    }
    .hero_cta {
        padding-bottom: 5px;
    }
    .slick-dots {
        bottom: 60px;
    }
}

/* nick added this */

@media screen and (min-width: 1430px) and (max-width: 1450px) {
    .productImage {
        max-height: 90%;
    }
    .snack {
        font-size: 4em;
    }
    .movieSlideTitle img {
        width: 80%;
    }
}

@media screen and (max-height: 630px) and (max-width: 1000) {
    .productImage {
        max-height: 500px;
    }
    .snack {
        font-size: 2.5em;
    }
}

@media screen and (min-width: 1360px) and (max-width: 1370px) {
    .productImage {
        max-height: 80%;
    }
    .snack {
        font-size: 3.5em;
    }
    .movieSlideTitle img {
        width: 60%;
    }
}

@media screen and (max-width: 414px) and (max-height:736px) and (orientation: portrait){
    ul.slick-dots {
        bottom: -60px !important;
        z-index: 400;
    }
}