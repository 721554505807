.video-js .vjs-big-play-button {
    visibility: hidden;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEkAAABHCAYAAABLeWqsAAAC7klEQVR4nO2c7UsUURSHn6C/vFo3lsWUXqioCCoqIUiCzL4ECUsEEiFREVFEEmUkaJpvOTH1O+1au+u+zMy9d+48X/wgzBwfZObcM797jyRJ8hg4ATykoiuppES/eCJZ94Gk0tWmU5LxTLLuAvuO6/OCbpKMF8AEcBv4GZ+aNv0kGW+AOnAd2C24Pi8YRJLxHmgAV4Dt8qtpM4wkYxloAheBzZzr84JRJBlfgEngHLBePjVtxpFkfAOmgdPAasb1eUEWkow14AxwCvgavpo2WUoyvgPn9dz6nPG1nZCHJOMHcElvxI/+q+hNnpKMHeAqcBJ4l/O9cqEIScYecEON6evi/9TRKVKSka4HZ7TkWSr43iPhQpKR3ndWi+lFRzUMhEtJncxJVsuDWv7DF0nGI+C4fnqDb5KMlmQ98KEYXyUZT4EacM/ltNR3ScZzybrjYloaiiTjpVqHW+q7CiE0ScZbNaXX1NHnSqiSjA9aG14GtvK6SeiSjE+aOlwANrK+eFkkGSvAlOZaa1ldtGySjFVNSqc1OR2Lskoy0tn7Wc3iV0a9SNklGRt6XjX1/BqKWCQZW3oTNvRmHIjYJBk76rHq6rn6EqskY0/d+4S6+a7ELsnY17qwpnXiASpJB0k0cahpAvGbSlJv5iVroZJ0OMeO+l6hQ/7+J1WSDtL1mVRJ+kPft1vsktIs6M3D+qRYJe0qA1pXJrQvsUnaVuazoQzoQMQiaVMZz6Yyn0NRdknrynROKuM5EmWVlOlksmyScplxl0VSrl9LQpdUyHe3UCUV+gU3NEmvtHQoNAsQiqQlLR1mXKRKfJe0qJjgrMt8kq+SWpIz50Et3knyMjPpiySv07cuJQWT43YhKbgdAUVKCnZvSRGSgt+llKek0ux3y0PSWFkgH8lSku3BnRonVeYjWUgq/W7ucSRFcy7AKJKiO2FiGEnRnlUyiKToT73pJ6k6P0l0k1SdxPUPnZKqM916kEpa0HB93ssKXQP8AuJFV/j61tgbAAAAAElFTkSuQmCC");
    background-repeat: no-repeat;
    background-size: 30px;
    background-position: 60% calc(50% - 0px);
    border: none !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    width: 75px !important;
    height: 75px !important;
    opacity: 0.8 !important;
    /* top: calc(50% - 50px) !important ;
    left: calc(50% - 50px) !important ; */

 }

 .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    content: "" !important;
}

.video-js .vjs-picture-in-picture-control {
    /* visibility: hidden !important; */
    display: none !important;
}

.vjs-track-settings-controls .vjs-default-button {
    margin-bottom: 0px !important;
}

.video-js .vjs-time-control {
    display: none !important;
}
.video-js .vjs-remaining-time {
    display: block !important;
}